export default function VisaSignature() {
	return (
		<div class="flex flex-col gap-8 md:mt-24">
			<img
				src="https://images.ctfassets.net/rdsy7xf5c8dt/75FoHN16e7IeILGnTq1z3J/0d2ee6544305a8b1b7d435eb3ade6cd1/Visa_Signature_Logo.svg"
				alt="Visa Signature®"
				width={96}
				height={42}
				class="mx-auto w-full max-w-64"
			/>

			<p>
				Your Visa Signature card entitles you to automatic Silver Status when you sign up for Troon Rewards®, for 10%
				saving on golf fees, merchandise and lessons at over 150 golf courses across the United States and world.
			</p>
		</div>
	);
}
